import React, { Component } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import "./Tarifs.css";
import MetaTags from "react-meta-tags";

class tarifs extends Component {
  render() {
    return (
      <Container fluid>
        <MetaTags>
          <title>Tarifs</title>
          <meta name="description" content="Tarifs et conditions" />
          <meta property="og:title" content="Tipourboire" />
        </MetaTags>
        <Row>
          <Col xs={12} sm={12} md={12} className="TitreTarifs">
            <h1>Tarifs</h1>
          </Col>
          <Col xs={12} sm={12} md={12} className="TitreTarifs">
            <h2>Client</h2>
          </Col>
        </Row>
        <Row className="tblClient">
          <Col xs={6} s={6} md={6} l={6} xl={6}>
            <h4 className="client">Engagement de durée</h4>
            <h4 className="client">Création du compte</h4>
            <h4 className="client">Frais sur pourboire individuel</h4>
            <h4 className="client">Frais sur pourboire commun</h4>
            <h4 className="client">Historique des dons</h4>
            <h4 className="client"> Commentaire client vers bénéficiaire</h4>
            <h4 className="client">Pourboire minimum</h4>
            <h4 className="client">Moyen de paiement</h4>
          </Col>
          <Col xs={6} s={6} md={6} l={6} xl={6}>
            <h4 className="client">Non</h4>
            <h4 className="client">Gratuit</h4>
            <h4 className="client">1,6% du pourboire + 0,10€</h4>
            <h4 className="client">1,6% du pourboire + 0,10€</h4>
            <h4 className="client">Gratuit si création de compte</h4>
            <h4 className="client"> Gratuit</h4>
            <h4 className="client">1€</h4>
            <h4 className="client">Visa-MasterCard</h4>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} className="TitreTarifs">
            <h2>Bénéficiaire</h2>
          </Col>
        </Row>
        <Row className="tblClient">
          <Col xs={6} s={6} md={6} l={6} xl={6}>
            <h4 className="client">Engagement de durée</h4>

            <h4 className="client">Frais de gestion Tipourboire</h4>
            <h4 className="client"> Création du compte</h4>
            <h4 className="client">Historique</h4>
            <h4 className="client">Commentaire client vers bénéficiaire</h4>

            <h4 className="client">Info annuelle des pourboires perçus</h4>
            <h4 className="client">
              Virement des pourboires sur le compte bancaire
            </h4>
          </Col>
          <Col xs={6} s={6} md={6} l={6} xl={6}>
            <h4 className="client">Non</h4>

            <h4 className="client">6%</h4>
            <h4 className="client"> Gratuit</h4>
            <h4 className="client"> Gratuit</h4>
            <h4 className="client"> Gratuit</h4>

            <h4 className="client"> Gratuit</h4>
            <h4 className="client">Gratuit</h4>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} className="TitreTarifs">
            <h2>Propriétaire d'un établissement</h2>
          </Col>
        </Row>
        <Row className="tblClient">
          <Col xs={6} s={6} md={6} l={6} xl={6}>
            <h4 className="client">Engagement de durée</h4>
            <h4 className="client">Prix Mensuel</h4>
            <h4 className="client">Frais de gestion Tipourboire</h4>
            <h4 className="client"> Création du compte</h4>
            <h4 className="client"> Mails d'affiliation bénéficiaire</h4>
            <h4 className="client">carte digital (menu et/ou prestations)</h4>
            <h4 className="client"> QR code carte digitale</h4>
            <h4 className="client">QR code collecte pourboire</h4>
            <h4 className="client">Pourboire collectif ou individuel</h4>
            <h4 className="client">Choix référent pourboire collectif</h4>
            <h4 className="client">Data clients</h4>
          </Col>
          <Col xs={6} s={6} md={6} l={6} xl={6}>
            <h4 className="client">Le mois en cours</h4>
            <h4 className="client">11.90€HT/mois</h4>
            <h4 className="client">Inclus</h4>
            <h4 className="client"> Inclus</h4>
            <h4 className="client"> Inclus</h4>
            <h4 className="client"> Inclus</h4>
            <h4 className="client"> Inclus</h4>
            <h4 className="client"> Inclus</h4>
            <h4 className="client"> Inclus</h4>
            <h4 className="client"> Inclus</h4>
            <h4 className="client"> Inclus</h4>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default tarifs;
