import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import "./Contact.css";
import { Container } from "react-bootstrap";
import { slide as Menu } from "react-burger-menu";
import MetaTags from "react-meta-tags";

class Contact extends Component {
  render() {
    return (
      <Container fluid>
        <MetaTags>
          <title>Contact</title>
          <meta
            name="description"
            content="Contactez-nous pour plus d'informations !"
          />
          <meta property="og:title" content="Tipourboire" />
        </MetaTags>
        <div className="backgroundColor desktop">
          <Row className="backTel desktop">
            <Row classnma="rowLogo backTel">
              <Col className="colLogo" xs={12} md={6}>
                <img className="logoServices" src="/images/logoJaune.png"></img>
              </Col>
            </Row>
          </Row>
        </div>

        <Row className="titreService desktop">
          <Col className="titreColor">
            <h1 id="ancreContact">Contact</h1>
          </Col>
        </Row>
        <Row className="servicesLigne desktop"></Row>
        <Row className="desktop">
          <Col className="colContact" xs={12} md={12}>
            {" "}
            <h1> Vous souhaitez en savoir plus ?</h1> <h1>Contactez-nous !</h1>
            <h1>contact@tiptothank.com</h1>
          </Col>
        </Row>
        {/*MOBILE */}
        <div className="backgroundColorMobile mobile">
          <Row className="logoMobile mobile">
            <Col>
              <img className="imgLogoMobile" src="images/logoJaune.png" />
            </Col>
          </Row>
        </div>

        <div className="backgroundContact">
          <Row className="titreService mobile">
            <Col className="titreColor">
              <h1 className="hmobile" id="ancreContact">
                Contact
              </h1>
            </Col>
          </Row>

          <Row className="servicesLigneMobile mobile"></Row>
          <Row className="mobile">
            <Col className="colContact" s={12} xs={12} md={12}>
              {" "}
              <h1 className="hmobile"> Vous souhaitez en savoir plus ?</h1>{" "}
              <h1 className="hmobile">Contactez-nous !</h1>
              <h1 className="hmobile">bab@tiptothank.com</h1>
            </Col>
          </Row>
        </div>

        {/* IPAD */}

        <div className="backgroundColorIpad ipad">
          <Row className="logoIpad ipad">
            <Col md={12}>
              <img className="imgLogoIpad" src="images/logoJaune.png" />
            </Col>
          </Row>
        </div>

        <div className="backgroundContact">
          <Row className="titreService ipad">
            <Col className="titreColor">
              <h1 className="hmobile" id="ancreContact">
                Contact
              </h1>
            </Col>
          </Row>

          <Row className="servicesLigneMobile ipad"></Row>
          <Row className="ipad">
            <Col className="colContact" s={12} xs={12} md={12}>
              {" "}
              <h1 className="hmobile"> Vous souhaitez en savoir plus ?</h1>{" "}
              <h1 className="hmobile">Contactez-nous !</h1>
              <h1 className="hmobile">bab@tiptothank.com</h1>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}

export default Contact;
