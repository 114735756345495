import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import "./Services.css";
import { Container } from "react-bootstrap";
import { slide as Menu } from "react-burger-menu";
import MetaTags from "react-meta-tags";
class Services extends Component {
  render() {
    return (
      <Container className="service" fluid>
        <MetaTags>
          <title>Connexion</title>
          <meta name="description" content="Le nouveau pourboire digital" />
          <meta property="og:title" content="Tipourboire" />
        </MetaTags>
        <Row className="servicePartie1">
          <Col className="partie2Titre " md={12}>
            <h1>Accédez à votre espace</h1>
          </Col>
          <Col className="partie2SousTitre" md={12}>
            <h3>
              Inscrivez-vous ou connectez-vous pour bénéficier de vos services.
            </h3>
          </Col>
        </Row>
        <Row className="partie3">
          <Col className="restaurateurBloc" md={4} s={12} xs={12}>
            <img src="/images/store.png" />
            <h3>Vous êtes propriétaire d'établissement</h3>
            <Button href="/waitingPage" className="btnServices">
              <a href="https://etablissement.tipourboire.com">
                C'est par ici !
              </a>{" "}
            </Button>
          </Col>
          <Col className="serveurBloc" md={4} s={12} xs={12}>
            <img src="/images/benef.png" />
            <h3>Vous êtes bénéficiaire</h3>
            <br />
            <Button className="btnServices">
              <a href="https://beneficiaire.tipourboire.com">C'est par ici !</a>{" "}
            </Button>
          </Col>
          <Col className="clientBloc" md={4} s={12} xs={12}>
            <img src="/images/rating.png" />
            <h3>Vous êtes client</h3>
            <br />
            <Button className="btnServices">A venir !</Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Services;
