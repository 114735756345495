import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { slide as Menu } from "react-burger-menu";

import { Container } from "react-bootstrap";

class menu extends Component {
  render() {
    return (
      <Menu>
        <a id="home" className="menu-item" href="/">
          Accueil
        </a>
        <a id="about" className="menu-item" href="/Services">
          Services
        </a>
        <a id="contact" className="menu-item" href="/contact">
          Contact
        </a>
      </Menu>
    );
  }
}

export default menu;
