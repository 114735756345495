import React, { Component, useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import "./Home.css";
import { Container, Form, ModalTitle } from "react-bootstrap";
import { slide as Menu } from "react-burger-menu";
import MetaTags from "react-meta-tags";
import video from "../videos/CLIENTE.mp4";
import videoPATRON from "../videos/PATRON.mp4";
import videoSERVEUR from "../videos/SERVEUR.mp4";
import Popup from "reactjs-popup";
import ReactReadMoreReadLess from "react-read-more-read-less";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import CookieConsent, { Cookies } from "react-cookie-consent";

function Connexion() {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [statut, setStatut] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const addNewPreInscription = () => {
    const data = {
      email: email,
      statut: statut,
    };
    const headers = new Headers({
      "Content-Type": "application/json",
    });

    const options = {
      method: "POST",
      body: JSON.stringify(data),
      headers: headers,
    };
    fetch(
      "https://back-end.osc-fr1.scalingo.io/vitrine/preInscription",
      options
    )
      .then((response) => {
        return response.json();
      })

      .then(
        (responseData) => {
          if (responseData.message === "serveur") {
            let link = " https://beneficiaire.tipourboire.com/inscription";
            window.location.href = link;
          } else {
            let link = " https://etablissement.tipourboire.com/inscription";
            window.location.href = link;
          }
        },

        (error) => {
          console.log(error);
        }
      );
  };

  return (
    <>
      <Button className="buttonPreInscr" onClick={handleShow}>
        Je m'inscris{" "}
        <Button onClick={handleShow} className="flecheInscr">
          {">"}
        </Button>
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        animation={true}
        backdrop={true}
        keyboard={false}
        style={{ overlay: { zIndex: 3 } }}>
        <Modal.Header closeButton>
          <Modal.Title>Inscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/*<img className="imgModal" src="/images/LogoOK.png" />*/}

          <Row>
            <Col className="colBenef" md={6} lg={6}>
              <a href="https://beneficiaire.tipourboire.com/inscription">
                <img className="logoPopup" src="/images/LogoOk.png" />
              </a>

              <Button
                href="https://beneficiaire.tipourboire.com/inscription"
                className="inscriptionModal">
                Je suis bénéficiaire du Tipourboire
              </Button>
            </Col>
            <Col className="colProprio" md={6} lg={6}>
              <a href="https://etablissement.tipourboire.com/inscription">
                <img className="logoPopup" src="/images/LogoOk.png" />
              </a>
              <Button
                href="https://etablissement.tipourboire.com/inscription"
                className="inscriptionModal">
                Je suis propriétaire d'un établissement
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cookie: false,
      statut: "",
      email: "",
      show: false,
    };

    this.hideModal = this.hideModal.bind(this);
  }

  hideModal = () => {
    this.setState({ show: false });
  };

  popupModal() {
    const handleClose = () => this.setState({ show: false });
    const handleShow = () => this.setState({ show: true });

    return (
      <>
        <Modal show={this.state.show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#f5a624" }}>Tipourboire</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="modalPopup">
              <h4>Grand test avec notre partenaire monétique</h4>
              <a className="texteOtherPop" href="/Services">
                <span className="textePopup">
                  Abonnement gratuit pour les patrons et seulement 6% de frais
                  de gestion sur les pourboires des employés
                </span>{" "}
                aujourd’hui,
              </a>{" "}
              <ul>
                <li>
                  les employés, c’est 10% de commission en + des 75% de base
                </li>
                <li>
                  les patrons, ce sont les coordonnées gratuites de vos clients
                </li>
              </ul>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <Col md={2}>
                <div className="imgPopup">
                  <img className="logoPopup" src="/images/LogoOk.png" />
                </div>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ show: true });
    }, 200000000);
  }
  render() {
    return (
      <Container fluid>
        <Modal show={this.state.show} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#f5a624" }}>Tipourboire</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="modalPopup">
              <h4>Offre de lancement national !</h4>
              <a className="texteOtherPop" href="/Services">
                <span className="textePopup">Inscrivez-vous</span> aujourd’hui,
                et gagnez 2 mois gratuits pour:{" "}
              </a>{" "}
              <ul>
                <li>
                  les serveurs & cuisiniers, c’est 10% de commission en + des
                  75% de base
                </li>
                <li>
                  les restaurateurs, ce sont les coordonnées gratuites de vos
                  clients
                </li>
              </ul>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <Col md={2}>
                <div className="imgPopup">
                  <img className="logoPopup" src="/images/LogoOk.png" />
                </div>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
        <MetaTags>
          <title>Accueil</title>
          <meta
            name="description"
            content="restaurateur, serveur ou client, cette appliction est faite pour vous !"
          />
          <meta property="og:title" content="Tipourboire" />
        </MetaTags>
        <Row className="partie1">
          <Col className="colButton" md={11}>
            <Button href="/Services" className="margin">
              Connexion
            </Button>
          </Col>

          <Col md={{ span: 5, offset: 7 }} className="colPre">
            <Connexion />
          </Col>

          <Col className="colUserMobile" md={12}>
            <a href="/Services">
              <img className="widthImUserMobile" src="/images/user.png" />
            </a>
          </Col>

          <Col className="logoPartie1" md={12}>
            <a href="/">
              <img className="puff-in-center" src="/images/logoBlanc.png" />
            </a>
          </Col>
        </Row>
        <Row>
          <Col className="mockup1">
            <img src="/images/mockup1.png" />
          </Col>
          <Col s={12} xs={12} className="mockup1mobile">
            <img src="/images/mockup1.png" />
          </Col>
          <Col md={4} className="mockup1Ipad">
            <img src="/images/mockup1.png" />
          </Col>
          <Col className="mockup2" md={5}>
            <img src="/images/mockup2.png" />
          </Col>
          <Col md={4} className="mockup2Ipad">
            <img src="/images/mockup2.png" />
          </Col>
        </Row>
        <Row className="partie2">
          <Col className="partie2Titre" md={12}>
            <h1>Tipourboire, c'est LA solution de pourboire digital !</h1>
          </Col>
          <Col className="partie2SousTitre" md={12}>
            <h3> Inscription rapide de l’établissement et des bénéficiaires</h3>

            <h3>Solution adaptée à tous les secteurs du service</h3>

            <h3>Pour donner un pourboire en 3 clics</h3>
          </Col>
        </Row>
        <Row className="partie3">
          <Col className="serveurBloc" md={4} s={12} xs={12}>
            <img src="/images/benef.png" />
            <h3>Vous êtes Bénéficiaire et collectez des pourboires ?</h3>

            <p>Augmentez vos revenus</p>
            <p>En proposant l’alternative</p>
            <p>du pourboire digital</p>

            <br />
            <Col>
              <Button href="/faq/#serveur" className="enSavoirPlus">
                En savoir plus
              </Button>
            </Col>
            <Popup
              trigger={<button className="trigger">Voir la vidéo</button>}
              position="right center">
              <div>
                <video className="video" autoPlay>
                  <source src={videoSERVEUR} type="video/mp4" />
                </video>
              </div>
            </Popup>
          </Col>
          <Col className="restaurateurBloc" md={4} s={12} xs={12}>
            <img src="/images/store.png" />
            <h3>Vous êtes propriétaire d’un établissement ?</h3>
            <p>Ouvrez votre compte</p>
            <p>Choisissez votre redistribution</p>
            <p>Augmentez les revenus de votre équipe</p>
            <p>En proposant le pourboire digital</p>
            <br />
            <Col>
              <Button href="/faq/#restaurateur" className="enSavoirPlus">
                En savoir plus
              </Button>
            </Col>
            <Popup
              trigger={<button className="trigger">Voir la vidéo</button>}
              position="right center">
              <div>
                <video className="video" autoPlay>
                  <source src={videoPATRON} type="video/mp4" />
                </video>
              </div>
            </Popup>
          </Col>
          <Col className="clientBloc" md={4} s={12} xs={12}>
            <img src="/images/rating.png" />
            <h3>Vous êtes client</h3>
            <p className="paraSer">Vous souhaitez laisser un pourboire ?</p>
            <p className="paraSer">Récompensez la qualité du service</p>
            <p className="paraSer">En donnant un pourboire digital</p>
            <br />
            <Col>
              <Button href="/faq/#client" className="enSavoirPlus">
                En savoir plus
              </Button>
            </Col>
            <Popup
              trigger={<button className="trigger">Voir la vidéo</button>}
              position="right center">
              <div>
                <video className="video" autoPlay>
                  <source src={video} type="video/mp4" />
                </video>
              </div>
            </Popup>
          </Col>
        </Row>
        <Row className="partie4">
          <Col className="partie4Titre" md={12}>
            <h1>Nos valeurs</h1>
          </Col>
          <Col className="partie4SousTitre" md={12}>
            <h4>
              Tipourboire s'engage à reverser 1% de son chiffre d'affaire{" "}
            </h4>
            <h4>afin de préserver notre planète</h4>
          </Col>
        </Row>
        <Row className="rowAsso">
          <Col className="colAsso" xs={6} md={6}>
            <a target="_blank" href="https://www.onepercentfortheplanet.fr/ ">
              <img className="asso" src="/images/ForThePlanet.png" />
            </a>
            <Col>
              <a
                className="trigger"
                target="_blank"
                href="https://www.youtube.com/watch?v=DCucazXVHtQ">
                Voir la vidéo
              </a>
            </Col>
          </Col>
          <Col className="colAsso" xs={6} md={6}>
            <a target="_blank" href="https://www.coeurdeforet.com/">
              <img className="asso2" src="/images/CoeurDeForet.png" />
            </a>
            <Col>
              <a
                className="trigger2"
                target="_blank"
                href="https://www.youtube.com/watch?v=MR28gSrduns">
                Voir la vidéo
              </a>
            </Col>
          </Col>
        </Row>
        <Row className="partie5">
          <Col className="partie5image" md={12}>
            <img src="/images/logoJaune.png" />
          </Col>
          <Col className="partie5Contact" md={12}>
            <h1>Contactez-nous</h1>
          </Col>
          <Col className="partie5Contact" md={12}>
            <h4>Vous souhaitez en savoir plus ?</h4>
            <h4>Nous répondons à vos questions à l'adresse suivante</h4>
            <a className="adresseContact" href="mailto:contact@tipourboire.com">
              contact@tipourboire.com
            </a>
          </Col>
          <Col className="partie5Contact" md={12}>
            <h1>Suivez-Nous</h1>
          </Col>
          <Col className="iconeFab">
            {" "}
            <a
              target="_blank"
              href="https://www.facebook.com/Tipourboire-110597077426094">
              <i class="fab fa-facebook-square"></i>
            </a>
            <a target="_blank" href="https://www.instagram.com/tipourboire/">
              <i class="fab fa-instagram-square"></i>
            </a>
            <i class="fab fa-linkedin"></i>
          </Col>
          <Col className="copy" s={12} md={12}>
            {" "}
            <a href="https://bonnie-and-cloud.com">
              Copyright @bonnie-and-cloud.com
            </a>{" "}
          </Col>
        </Row>
        <Row>
          <Col className="cookie">
            <CookieConsent
              location="bottom"
              buttonText="J'accepte"
              declineButtonText="Je refuse"
              expires={30}
              enableDeclineButton
              onDecline={() => {
                alert(
                  "REFUS de cookies, votre choix à bien été pris en compte."
                );
              }}
              cookieName="Tipourboire"
              style={{ background: "#ffffff", color: "#555" }}
              declineButtonStyle={{
                borderRadius: 12,
                padding: 8,
                color: "#fff",
                fontSize: "18px",
                background: "#f5a624",
                fontWeight: "bold",
              }}
              buttonStyle={{
                borderRadius: 12,
                padding: 8,
                color: "#fff",
                fontSize: "18px",
                background: "#f5a624",
                fontWeight: "bold",
              }}
              style={{
                fontSize: "10px",
                fontfamily: "Montserrat",
                fontWeight: "bold",
                zIndex: 2000,
              }}>
              Le Site Tipourboire utilise différents cookies afin d’améliorer
              ses services et effectuer des suivis d’audience. Certains cookies
              sont indispensables au fonctionnement du Site. Vous pouvez
              accepter ces cookies, les refuser, ou gérer vos préférences. Vous
              pouvez consulter notre{" "}
              <a
                href="/cookies/POLITIQUE_DE_COOKIES.pdf"
                target="_blank"
                style={{
                  fontSize: "14px",
                  fontfamily: "Montserrat",
                  fontWeight: "bold",
                  color: "#f5a624",
                }}>
                Politique de cookies
              </a>
            </CookieConsent>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Home;
