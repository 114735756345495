import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import "./Footer.css";
import {
  Container,
  Navbar,
  Nav,
  Form,
  FormControl,
  NavbarBrand,
} from "react-bootstrap";

class Footer extends Component {
  render() {
    return (
      <Navbar sticky="bottom">
        <Nav>
          <Nav.Link className="typoFooter" href="/">
            @Tipourboire
          </Nav.Link>
          <Nav.Link
            className="typoFooter"
            href="/MentionsLegales/mentionsLégales.pdf">
            Mentions Légales
          </Nav.Link>
          <Nav.Link
            className="typoFooter"
            href="/confidentialité/Politique_de_confidentialité_vitrine.pdf">
            Confidentialité
          </Nav.Link>
          <Nav.Link
            className="typoFooter"
            href="/cookies/POLITIQUE_DE_COOKIES.pdf">
            Cookies
          </Nav.Link>
          <Nav.Link className="typoFooter" target="_blank" href="/CGU/CGU.pdf">
            CGU
          </Nav.Link>
          <Nav.Link className="typoFooter" target="_blank" href="/Tarifs">
            {" "}
            Nos tarifs
          </Nav.Link>
          <Nav.Link className="typoFooter" target="_blank" href="/faq/#faq">
            FAQ
          </Nav.Link>
        </Nav>
      </Navbar>
    );
  }
}

export default Footer;
