import React, { Component, Button, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./componentsFR/Home/Home";
import Menu from "./componentsFR/Menu/Menu";
import Footer from "./componentsFR/Footer/Footer";
import Contact from "./componentsFR/Contact/Contact";
import Service from "./componentsFR/Services/Services";
import FAQ from "./componentsFR/Services/FAQservice";
import Tarifs from "./componentsFR/Tarifs/Tarifs";
import waitingPage from "./componentsFR/waitingPage/waitingPage";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

/* Main app component*/
class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Router>
        <div className="main-container">
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/contact" exact component={Contact} />
            <Route path="/services" exact component={Service} />
            <Route path="/waitingPage" exact component={waitingPage} />
            <Route path="/Services" exact component={Service} />
            <Route path="/faq" exact component={FAQ} />
            <Route path="/Tarifs" exact component={Tarifs} />
          </Switch>
          <Footer />
        </div>
      </Router>
    );
  }
}
export default App;
