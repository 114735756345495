import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import "./FAQservice.css";
import { Container } from "react-bootstrap";
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";

class NameContainer extends Component {
  render() {
    return (
      <div>
        {this.props.questions.map((name) => (
          <Name name={name} />
        ))}
      </div>
    );
  }
}

class Name extends Component {
  render() {
    return <div>{this.props.name}</div>;
  }
}

class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerms: "",
    };
  }

  OnClickFrequent = () => {
    this.setState({ displayTextFrequent: !this.state.displayTextFrequent });
  };
  OnClickFiscalite = () => {
    this.setState({ displayText: !this.state.displayText });
  };

  OnClickSecurite = () => {
    this.setState({ displayTextSecu: !this.state.displayTextSecu });
  };

  OnClickTarif = () => {
    this.setState({ displayTextTarif: !this.state.displayTextTarif });
  };

  OnClickCgu = () => {
    this.setState({ displayTextCgu: !this.state.displayTextCgu });
  };
  OnClickPartenaire = () => {
    this.setState({ displayTextPartenaire: !this.state.displayTextPartenaire });
  };

  editSearchTerm = (e) => {
    this.setState({ searchTerm: e.target.value });
  };

  dynamicSearch = () => {
    return this.state.questions.filter((questions) =>
      questions.toLowerCase().includes(this.state.searchTerms.toLowerCase())
    );
  };

  render() {
    return (
      <Container fluid className="FAQ">
        <MetaTags>
          <title>Services</title>
          <meta name="description" content="Le nouveau pourboire digital" />
          <meta property="og:title" content="Tipourboire" />
        </MetaTags>
        <Row className="backgroundPartie1">
          <Col className="colButton" s={12} xs={12} md={12}>
            <Button href="/Services">Connexion</Button>
          </Col>
          <Col className="colUserMobile" s={12} xs={12} md={12}>
            <a href="/Services">
              <img src="/images/user.png" />
            </a>
          </Col>

          <Col className="logoPartie1faq" s={12} xs={12} md={12}>
            <a href="/">
              <img src="/images/logoJaune.png" />
            </a>
          </Col>
        </Row>
        <Row id="client" className="backgroundClient"></Row>
        <Row>
          <Col className="paraClientFaq" xs={12} s={12} md={12}>
            <h1>
              <strong>Vous êtes client</strong>
              {""}
            </h1>
            <h2>
              Récompensez la qualité de service même quand vous n'avez pas de
              monnaie !
            </h2>
          </Col>
          {/*<Col className="paraClientFaqh3" _bl xs={12} s={12} md={12}>
            <Button href="https://client.tipourboire.com" className="butInscr">
              Créez votre compte ici
            </Button>
    </Col>*/}
          <Col className="paraClientTexteFaq" xs={12} s={12} md={12}>
            <p>
              <strong>Pour donner rien de plus facile :</strong>
            </p>
            <p className="ligne">
              Scannez le QR Code présent dans l'établissement
              <br />
              et selon le mode de fonctionnement de l'établissement,
              <br />
              laissez un pourboire collectif ou individuel
              <br />
              En saisissant le montant souhaité à partir de 1 euro
              <br />
              Et effectuez votre paiement sécurisé
            </p>
            <br />
            <p className="ligne">
              <strong>
                Vous avez même la possibilité de laisser un commentaire
                <br /> pour accompagner votre don.
              </strong>
            </p>
          </Col>
        </Row>
        <Row className="rowIconeClient">
          <Col md={2} s={6} xs={4} className="blocClient">
            <img className="img1" src="/images/greeting.png" />
          </Col>
          <Col md={2} s={6} xs={4} className="blocClient">
            <img className="img2" src="/images/approved.png" />
          </Col>
          <Col md={2} s={6} xs={4} className="blocClient">
            <img className="img3" src="/images/pouce.png" />
          </Col>
        </Row>
        <Row className="rowIconeClient">
          <Col md={2} s={6} xs={4} className="blocClient">
            <img className="img1" src="/images/save-money.png" />
          </Col>
          <Col md={2} s={6} xs={4} className="blocClient">
            <img className="img2" src="/images/approval.png" />
          </Col>
          <Col md={2} s={6} xs={4} className="blocClient">
            <img className="img3" src="/images/chat-2.png" />
          </Col>
        </Row>
        <Row className="rowButFaq center">
          <Col xs={12} s={12} md={12} className="colInter">
            <p>
              <strong>Des questions ? Trouvez toutes les réponses ici</strong>
            </p>
          </Col>
          <Col xs={12} s={12} md={12} className="center">
            <Button href="#faq" className="butFaq">
              Voir la FAQ
            </Button>
          </Col>
        </Row>
        <Row id="serveur" className="backgroundServeur"></Row>
        <Row>
          <Col className="paraClientFaq" xs={12} s={12} md={12}>
            <h1>
              <strong>Vous êtes bénéficiaire</strong>
              {""}
            </h1>
            <h2>Et collectez des pourboires ?</h2>
            <h2>
              Augmentez vos revenus en proposant l'alternative du pouboire
              digital.
            </h2>
          </Col>

          <Col className="paraClientFaqh3" xs={12} s={12} md={12}>
            <Button
              href="https://beneficiaire.tipourboire.com"
              className="butInscr">
              Créez votre compte ici
            </Button>
          </Col>
          <Col className="paraClientTexteFaq" xs={12} s={12} md={12}>
            <p className="ligne orange">
              Vos clients manquent parfois d’espèces alors qu’ils souhaiteraient
              récompenser la qualité de votre service ?
              <br /> Avec Tipourboire, ce n’est plus un souci !
            </p>
            <br />
            <p className="ligne">
              Pour proposer à vos clients de vous donner un pourboire digital,
              rien de plus simple :
            </p>
            <p className="ligne">
              <strong>Présentez-lui le QR Code de votre établissement</strong>
            </p>
            <br />
            <p className="ligne">
              Le client pourra ainsi accéder à la page de celui-ci, afin de
              donner un pourboire individuel ou collectif, selon le paramétrage
              réalisé par le propriétaire.
            </p>

            <p className="ligne">
              Vos clients verront votre photo pour vous donner un pourboire et
              ils pourront vous envoyer un commentaire
            </p>
            <p>
              <strong>
                Ensuite, vous pourrez visualiser les pourboires dans votre
                espace sécurisé et procéder au virement sur votre compte
                bancaire quand vous le souhaitez
                <br />
                <br /> (après avoir renseigné vos informations personnelles)
              </strong>
            </p>
            {/* <p className="ligne">
              A partir de 80€/mois de pourboire digitaux, prenez l'option
              "Abonnement Premium" pour gagner encore + en baissant les frais de
              gestion à 15%, c'est seulement 7,90€TTC/mois sans engagement de
              durée
                </p>*/}
            <br />
            {/*<p className="ligne">
              <strong>
                Vous êtes satisfaits : parlez-en autour de vous! A chaque
                parrainage d'un serveur ou restaurateur, vous et votre ami,
                gagnez 2 mois d'abonnements Premium.
              </strong>
    </p>*/}
          </Col>
        </Row>
        <Row className="rowIconeClient">
          <Col md={2} s={6} xs={4} className="blocClient">
            <img className="img1" src="/images/euro.png" />
          </Col>
          <Col md={2} s={6} xs={4} className="blocClient">
            <img className="img2" src="/images/greeting.png" />
          </Col>
          <Col md={2} s={6} xs={4} className="blocClient">
            <img className="img3" src="/images/account.png" />
          </Col>
        </Row>
        <Row className="rowIconeClient">
          <Col md={2} s={6} xs={4} className="blocClient">
            <img className="img1" src="/images/tip.png" />
          </Col>
          <Col md={2} s={6} xs={4} className="blocClient">
            <img className="img2" src="/images/chat-serv.png" />
          </Col>
          <Col md={2} s={6} xs={4} className="blocClient">
            <img className="img3" src="/images/cv.png" />
          </Col>
        </Row>
        <Row className="rowButFaq">
          <Col xs={12} s={12} md={12} className="center">
            <p>
              <strong>Des questions ? Trouvez toutes les réponses ici</strong>
            </p>
          </Col>
          <Col xs={12} s={12} md={12} className="center">
            <Button href="#faq" className="butFaq">
              Voir la FAQ
            </Button>
          </Col>
        </Row>
        <Row id="restaurateur" className="backgroundRestaurateur"></Row>
        <Row className="justifyBenef">
          <Col className="paraClientFaq" xs={12} s={12} md={8}>
            <h1>
              <strong>Vous êtes propriétaire d'un établissement</strong>
              {""}
            </h1>
            <h2>
              Boostez vos équipes en augmentant leurs revenus
              <br />
              Grâce au pourboire digital
            </h2>

            <h3 className="deuxMin">En 2 minutes</h3>
          </Col>
          <Col className="paraClientFaqh3" xs={12} s={12} md={6}>
            <Button
              href="https://etablissement.tipourboire.com"
              className="butInscr">
              Créez votre compte ici
            </Button>

            <h3>
              Définissez votre mode de distribution (individuel ou collectif)
            </h3>

            <h3>
              Créez les profils (pour la distribution individuelle) et désignez
              votre référent (pour la distribution collective)
            </h3>
          </Col>
          <Col className="paraClientTexteFaq" xs={12} s={12} md={12}>
            <p>
              <strong>
                Le tour est joué ! Un QR code d’accès personnalisé est généré et
                il ne vous reste qu’à le mettre à disposition de vos clients.
              </strong>
              <br />
              <br />
              Quant à votre équipe, chaque bénéficiaire (ou le référent) recevra
              un email qui les invitera à compléter leurs informations
              personnelles
            </p>
            <br />

            <p>
              En plus, nous vous offrons la possibilité de générer un QR code
              supplémentaire pour mettre en avant votre carte <br />
              (menu ou prestation).
            </p>
            <p className="ligne">
              A tout moment, via votre espace propriétaire, vous pouvez
              récupérer vos QR code et modifier votre photo de carte.
            </p>
            <br />
            <p className="ligne">
              Votre QR code peut aussi être mis en évidence sur :
            </p>
            <p className="ligne">Votre addition via votre logiciel de caisse</p>
            <p className="ligne">Votre comptoir près de votre caisse </p>
            <br />

            {/* <p className="ligne">
              prenez l'option "Premium" pour seulement 14,90€TTC/mois sans
              engagement de durée.
            </p>/*}
            <br />
            {/*<p className="ligne">
              <strong>
                Vous êtes satisfaits : parlez-en autour de vous! A chaque
                parrainage d'un restaurateur, vous et votre confrère gagnez 2
                mois d'abonnements Premium.
              </strong>
  </p>*/}
          </Col>
        </Row>
        <Row className="rowIconeClient">
          <Col md={2} s={6} xs={4} className="blocClient">
            <img className="img1" src="/images/restau.png" />
          </Col>
          <Col md={2} s={6} xs={4} className="blocClient">
            <img className="img2" src="/images/greeting.png" />
          </Col>
          <Col md={2} s={6} xs={4} className="blocClient">
            <img className="img3" src="/images/carte.png" />
          </Col>
        </Row>
        <Row className="rowIconeClient">
          <Col md={2} s={6} xs={4} className="blocClient">
            <img className="img1" src="/images/data.png" />
          </Col>
          <Col md={2} s={6} xs={4} className="blocClient">
            <img className="img2" src="/images/don.png" />
          </Col>
          <Col md={2} s={6} xs={4} className="blocClient">
            <img className="img3" src="/images/menu.png" />
          </Col>
        </Row>
        <Row className="rowButFaq">
          <Col xs={12} s={12} md={12} className="center">
            <p>
              <strong>Des questions ? Trouvez toutes les réponses ici</strong>
            </p>
          </Col>
          <Col xs={12} s={12} md={12} className="center">
            <Button href="#faq" className="butFaq">
              Voir la FAQ
            </Button>
          </Col>
        </Row>

        <Row className="titleFaq">
          <Col className="paraClientFaq" id="FAQ" xs={12} s={12} md={6}>
            <h1>
              <strong>FAQ</strong>
              {""}{" "}
            </h1>
            <h2>Toutes les réponses à vos questions</h2>
          </Col>
        </Row>
        <Row id="faq" className="rowFAQ">
          <Col md={2} s={4} xs={4} className="blocClientFaq">
            <Col>
              <img className="img1" src="/images/smartphone.png" />
            </Col>
            <Button className="butCgu" onClick={this.OnClickCgu}>
              Utilisation
            </Button>
          </Col>

          {this.state.displayTextCgu && (
            <Row className="rowFiscalite">
              <Col>
                <h1 className="sousTitreFaq">
                  <strong>Utilisation</strong>
                </h1>
              </Col>
              <Col md={12}>
                <h3>
                  <strong></strong>Comment je fais pour donner un pourboire
                  digital ?
                </h3>
                <p>
                  1. Pas besoin de télécharger une application, vous scannez le
                  QR Code de l'établissement.
                </p>
                <p>
                  2. Vous sélectionnez votre bénéficiaire par sa photo ou le
                  pourboire commun et le montant que vous voulez lui donner.
                </p>
                <p>3. Vous payez en saisissant votre CB.</p>
                <br />
              </Col>

              <Col md={12}>
                <h3>
                  <strong></strong>Est-ce que tous les établissements sont
                  inscrits sur Tipourboire ?
                </h3>
                <p>
                  C’est notre objectif, et vous pouvez nous aider en partageant
                  votre super expérience.
                </p>
                <br />
                <h3>
                  <strong></strong>Que faut-il faire pour créer mon compte ?
                </h3>
                <p>
                  Aller sur www.tipourboire.com et fournir, en fonction de votre
                  profil :
                </p>

                <p>
                  - bénéficiaire : Photo-Nom-Prénom, pièce d’identité et RIB
                  pour recevoir les pourboires.
                </p>
                <p>
                  - propriétaire d'un établissement : Informations de
                  l'établissement et du patron, service proposé, politique de
                  distribution : individuelle ou collective.
                </p>
                <br />
                {/*  <h3>
                  <strong></strong>Est-ce que je peux recommander et parrainer
                  mon entourage? si, oui: comment et quels sont les bénéfices?
                </h3>
                <p>
                  Tout le monde peut parler de Tipourboire pour agrandir la
                  communauté. Les Serveurs et Restaurateurs (en allant sur leur
                  espace Tipourboire) ont toujours un bénéfice <br /> financié à
                  parrainer de nouveaux Serveurs et Restaurateurs (hors
                  Restaurateurs qui parrainent des serveurs):
                </p>
                <p>- pour les parrains: 2 mois d'abonnement Premium offerts</p>
                <p>- pour les parrainés: 2 mois d'abonnement Premium offert</p>
                <p>+ vous parrainez d'amis, + les mois offerts se cumulent !</p>
                  <br />*/}
              </Col>
              <Col md={12}>
                <h3>
                  <strong></strong>Est-ce que je peux donner un pourboire après
                  être sorti du commerce ?
                </h3>
                <p>Oui, du moment que vous avez scanné le QR Code.</p>
                <br />
              </Col>
              <Col md={12}>
                <h3>
                  <strong>
                    En tant que client pourquoi ne pas choisir entre don
                    individuel et collectif ?
                  </strong>
                </h3>
                <p>
                  Car c’est le propriétaire de l'établissement qui choisit la
                  politique de distribution dans son commerce.
                </p>
                <br />
              </Col>
              <Col md={12}>
                <h3>
                  <strong></strong>Est-ce que le pourboire digital remplace le
                  pourboire en monnaie ?
                </h3>
                <p>
                  Non, il vient en complément et fait suite à un constat simple
                  : nous avons de moins en moins de monnaie sur nous et toujours
                  envie de récompenser un service de qualité.
                </p>
                <br />
              </Col>
              {/*  <Col md={12}>
                <h3>
                  <strong></strong>Est-ce que l’abonnement Premium a un
                  engagement de durée?
                </h3>
                <p>Non, seul le mois en cours est dû.</p>
                <br />
                </Col>*/}
              <Col md={12}>
                <h3>
                  <strong></strong>Est-ce que je peux utiliser Tipourboire dans
                  d'autres domaines du service ? (coiffure, taxi, hôtels,
                  etc...)
                </h3>
                <p>Tout à fait, il vous suffit de paramétrer votre compte.</p>
                <br />

                <h3>
                  <strong></strong>Comment je touche mes pourboires sur mon
                  compte bancaire ?
                </h3>
                <p>
                  Rien de plus simple. Une fois vos informations personnelles
                  saisies, vous pouvez transférer vos pourboires depuis votre
                  espace Tipourboire vers votre compte bancaire quand vous le
                  souhaitez. Cela prendra 48h maximum pour être visible sur
                  votre compte.
                </p>
                <br />
                <h3>
                  <strong>
                    Comment je m’affilie comme bénéficiaire d'un établissement ?
                  </strong>
                </h3>
                <p>
                  Mon patron m’explique la politique de distribution des
                  pourboires au sein de son établissement, puis m’envoie un mail
                  d’affiliation que je dois accepter pour être dans la liste des
                  bénéficiaires visibles sur son espace Tipourboire.
                </p>
                <p>
                  En cas de départ ou désaccord avec ma direction, je peux me
                  désaffilier à tout moment et mon patron peut le faire aussi
                  pour moi.
                </p>
                <br />
              </Col>
              <Col md={12}>
                <h3>
                  <strong></strong>Puis-je utiliser Tipourboire en tant que
                  saisonnier et/ou dans plusieurs établissements ?
                </h3>
                <p>
                  Oui du moment que le patron de l’établissement a accepté votre
                  affiliation.
                </p>
                <br />
              </Col>
              <Col md={12}>
                <h3>
                  <strong></strong>Comment je mets en place ma carte digitale ?
                </h3>
                <p>
                  Simple et gratuit, il suffit en tant que propriétaire, de vous
                  rendre dans votre espace afin de charger votre image et
                  générer votre QR Code que vous pouvez ensuite charger pour
                  l'imprimer. Pour sa mise à jour, chaque nouvelle photo
                  effacera la précédente afin que le client ne voit que le menu
                  du jour actuel.
                </p>

                <br />

                <h3>
                  <strong></strong>Comment est redistribué le pourboire commun ?
                </h3>
                <p>
                  Le patron du commerce désigne, dans son espace Tipourboire, un
                  référant parmi les membres de son équipe. Ce référent est
                  ensuite en charge de la redistribution du pourboire commun.
                </p>

                <br />
                <h3>
                  <strong></strong>Comment je mets en place mon QR Code ?
                </h3>
                <p>Il vous suffit, depuis votre espace propriétaire, de :</p>
                <li>
                  le télécharger pour ensuite l’insérer sur votre ticket de
                  caisse (voir paramètres logiciel de caisse) en dessous du nom
                  de votre commerce.
                </li>

                <li>
                  l’imprimer et le coller où cela vous semble pertinent (tables,
                  pot à pourboire, tirelire, support près de la caisse ...).
                </li>

                <br />
              </Col>
              <Col md={12}>
                <h3>
                  <strong></strong>Comment créer ma liste de bénéficiaires
                  visibles sur Tipourboire ?
                </h3>
                <p>
                  Il vous suffit d’envoyer un mail d’affiliation (prédéfini dans
                  votre espace Tipourboire) à vos bénéficiaires, qu’ils doivent
                  ensuite accepter pour que leur compte soit actif.
                </p>
                <br />
              </Col>
              <Col md={12}>
                <h3>
                  <strong>
                    Est-ce que je suis obligé(e) de prendre le service carte
                    digitale pour que les clients de mon commerce puissent
                    donner un pourboire ?
                  </strong>
                </h3>
                <p>
                  Non, c'est un service additionnel et gratuit pour vous offrir,
                  à tous, encore plus de services.
                </p>
                <br />
              </Col>
            </Row>
          )}
          <Col md={2} s={4} xs={4} className="blocClientFaq chatFAQ">
            <Col>
              <img className="img1 chat" src="/images/chatfaq.png" />
            </Col>
            <Col>
              <Button className="freqQuest" onClick={this.OnClickFiscalite}>
                Top des questions
              </Button>
            </Col>
          </Col>

          {this.state.displayText && (
            <Row className="rowFiscalite">
              <Col>
                <h1 className="sousTitreFaq">
                  <strong>Questions fréquentes</strong>
                </h1>
              </Col>
              <Col md={12}>
                <h3>
                  <strong></strong>Comment je fais pour donner un pourboire
                  digital ?
                </h3>
                <p>
                  1. Pas besoin de télécharger une application, vous scannez le
                  QR Code de l'établissement.
                </p>
                <p>
                  2. Vous sélectionnez votre bénéficiaire par sa photo ou le
                  pourboire commun et le montant que vous voulez lui donner.
                </p>
                <p>3.Vous payez en saisissant votre CB.</p>
                <br />
              </Col>
              <Col md={12}>
                <h3>
                  <strong></strong>Est-ce que tous les établissements sont
                  inscrits sur Tipourboire ?
                </h3>
                <p>
                  C’est notre objectif, et vous pouvez nous aider en partageant
                  votre super expérience.
                </p>
              </Col>
              <Col md={12}>
                <h3>
                  <strong></strong>Comment je touche mes pourboires sur mon
                  compte bancaire ?
                </h3>
                <p>
                  Rien de plus simple. Une fois vos informations personnelles
                  saisies, vous pouvez transférer vos pourboires depuis votre
                  espace Tipourboire vers votre compte bancaire quand vous le
                  souhaitez. Cela prend 48h maximum pour être visible sur votre
                  compte.
                </p>
                <br />
              </Col>
              <Col md={12}>
                <h3>
                  <strong></strong>Comment je mets en place ma carte digitale ?
                </h3>
                <p>
                  Simple et gratuit, il suffit en tant que propriétaire, de vous
                  rendre dans votre espace afin de charger votre image et
                  générer votre QR Code que vous pouvez ensuite charger pour
                  l'imprimer. Pour sa mise à jour, chaque nouvelle photo
                  effacera la précédente afin que le client ne voit que le menu
                  du jour actuel.
                </p>

                <br />
                <h3>
                  <strong></strong>Comment créer ma liste de bénéficiaires
                  visibles sur Tipourboire ?
                </h3>
                <p>
                  Il vous suffit d’envoyer un mail d’affiliation (prédéfini dans
                  votre espace Tipourboire) à vos bénéficiaires, qu’ils doivent
                  accepter afin que leur compte soit actif.
                </p>
                <br />
              </Col>
              <Col md={12}>
                <h3>
                  <strong>
                    Est-ce que je suis obligé(e) de prendre le service carte
                    digitale pour que les clients de mon commerce puissent
                    donner un pourboire ?
                  </strong>
                </h3>
                <p>
                  Non, c'est un service additionnel et gratuit pour vous offrir,
                  à tous, encore plus de services.
                </p>
                <br />
              </Col>

              <Col md={12}>
                <h3>
                  <strong></strong>Mes données et paiements sont-ils sécurisés ?
                </h3>
                <p>
                  Oui et elles ne sont pas stockées sur Tipourboire car nous
                  travaillons avec MangoPay.
                </p>
                <br />
              </Col>
            </Row>
          )}

          <Col md={2} s={4} xs={4} className="blocClientFaq">
            <Col>
              <img className="img1" src="/images/verifiedfaq.png" />
            </Col>
            <Button className="butSecu" onClick={this.OnClickSecurite}>
              Sécurité
            </Button>
          </Col>

          {this.state.displayTextSecu && (
            <Row className="rowFiscalite">
              <Col>
                <h1 className="sousTitreFaq">
                  <strong>Securité</strong>
                </h1>
              </Col>
              <Col md={12}>
                <h3>
                  <strong></strong>Mes données et paiements sont-ils sécurisés ?
                </h3>
                <p>
                  Oui et elles ne sont pas stockées sur Tipourboire car nous
                  travaillons avec MangoPay.
                </p>
                <br />
              </Col>

              <Col md={12}>
                <h3>
                  <strong></strong>Mes coordonnées sont-elles transmises à un
                  tiers ?
                </h3>
                <p>
                  Oui si vous en donnez l’autorisation en cochant la case légale
                  et uniquement à l'établissement dans lequel vous avez donné le
                  pourboire.
                </p>
              </Col>

              <Col md={12}>
                <h3>
                  Comme bénéficiaire, je peux savoir qui m'a donné un pourboire
                  et combien ?
                </h3>
                <p>oui, dans l'historique consultable par le bénéficiaire.</p>
              </Col>
            </Row>
          )}
        </Row>
        <Row className="rowFAQ rowEnd">
          <Col md={2} s={4} xs={4} className="blocClientFaq">
            <Col md={12}>
              <img className="img1 tax" src="/images/tax-2.png" />
            </Col>{" "}
            <Button onClick={this.OnClickFrequent}>Fiscalité</Button>
          </Col>

          {this.state.displayTextFrequent && (
            <Row className="rowFiscalite">
              <Col>
                <h1 className="sousTitreFaq">
                  <strong>Fiscalité</strong>
                </h1>
              </Col>
              <Col md={12}>
                <h2>bénéficiaire :</h2>
                <h3>
                  <strong></strong>Dois-je déclarer ces pourboires et vais-je
                  payer des impôts ?
                </h3>
                <p>
                  Oui vous devez déclarer les pourboires perçus comme revenu
                  mais la loi de finances vous éxonère d'impôts en 2022 et 2023
                  .
                  <a href="https://www.capital.fr/votre-argent/lassemblee-vote-la-defiscalisation-des-pourboires-en-2022-et-2023-jusqua-2000-euros-de-salaire-1417004">
                    <p className="linkArticle">Lien article ici</p>
                  </a>
                </p>
                <br />
              </Col>

              <Col md={12}>
                <h2>propriétaire d'un établissement :</h2>
                <h3>
                  <strong></strong>Comme propriétaire d'un établissement, est-ce
                  que je dois payer de la TVA, des charges sociales & patronales
                  ?
                </h3>
                <p>
                  Non et pour 2 raisons :
                  <br />
                  - Avec Tipourboire, ce sont les bénéficiaires qui perçoivent
                  directement les pourboires sans passer par la comptabilité de
                  votre établissement .
                  <br />- Avec la nouvelle loi de finance qui vous exonère de
                  charges sociales et patronales sur les pourboires en 2022 et
                  2023.
                  <a href="https://www.efl.fr/actualite/pourboires-verses-2022-2023-seront-exoneres-cotisations-contributions-sociales_fa33cab7f-0f55-48d1-ab78-cc72d911a213">
                    <p className="linkArticle">Lien article ici</p>
                  </a>
                </p>
                <br />
                <h3>
                  <strong></strong>Est-ce que je dois tenir une comptabilité
                  spécifique ?
                </h3>
                <p>
                  Non car ce sont les bénéficiaires qui perçoivent directement
                  sans passer par vous contrairement au registre de pourboire
                  que vous devez tenir en cas de pourboire en monnaie dont vous
                  gérez la redistribution.
                </p>
                <br />
              </Col>
              <Col md={12}>
                <h2>Client :</h2>
                <h3>
                  <strong></strong>Est-ce que je peux déduire de mes impôts la
                  partie reversée à l’association Coeur de Forêt via
                  1%ForThePlanet sur les pourboires que j’ai donné au
                  bénéficiaire ?
                </h3>
                <p>
                  Non, car c’est Tipourboire qui encaisse puis reverse aux
                  bénéficiaires leur Tips (frais de gestion déduits) et à
                  l’association via ses partenaires de paiement sécurisé
                  MangoPay.
                </p>
                <br />
              </Col>
            </Row>
          )}

          <Col md={2} s={4} xs={4} className="blocClientFaq">
            <Col md={12}>
              <img className="img1 tarif" src="/images/fric.png" />
            </Col>
            <Button onClick={this.OnClickTarif} className="butTarif">
              Tarif
            </Button>
          </Col>
          {this.state.displayTextTarif && (
            <Row className="rowFiscalite">
              <Col md={12}>
                <Col>
                  <h1 className="sousTitreFaq">
                    <strong>Tarif</strong>
                  </h1>
                </Col>
                <h3>
                  <strong></strong>Combien ça coûte ?
                </h3>

                <p>
                  Pour le client, pourboire commun et individuel : 1,6% + 0,10€
                  afin de sécuriser la bonne attribution du pourboire aux
                  bénéficiaires, par exemple pour 1€ donné, c'est 0,116€ de
                  frais soit un total de 1,116€ débité de la CB.
                </p>

                <p>
                  Pour le propriétaire d'établissement, un abonnement
                  obligatoire à hauteur de 11.90€HT/mois.
                </p>
                <p>
                  Pour le bénéficiaire, uniquement des frais de gestion perçus
                  par Tipourboire (6%) qui sont déduits du total des pourboires
                  reversés chaque mois.
                </p>
                <br />
              </Col>

              <Col md={12}>
                <h3>
                  <strong></strong>Pourquoi un minimum de pourboire de 1€ ?
                </h3>
                <p>
                  Car c’est le montant minimum de pourboire pour que les frais
                  de transactions perçus par MangoPay ne soient pas trop
                  pénalisants pour les clients.
                </p>
                <br />
                <h3>
                  <strong></strong>Quelle est la partie reversée aux
                  associations environnementales ?
                </h3>
                <p>
                  Nous nous sommes engagés à reverser 1% de notre chiffre
                  d'affaire annuel.
                </p>
                <br />
              </Col>
              <Col md={12}>
                <h3>
                  <strong></strong>Qu’est-ce qui est inclus de base ?
                </h3>
                <p>Pour les bénéficiaires :</p>
                <p>La mise à disposition de Tipourboire.</p>
                <p>Un historique des pourboires individuel reçus.</p>

                <p>
                  L’accès aux commentaires laissés par les clients ayant donné
                  un pourboire.
                </p>
                <p>
                  Un récapitulatif en fin d’année des pourboires perçus pour la
                  déclaration aux impôts.
                </p>

                <br />

                <p>Pour les propriétaires d'un établissement :</p>
                <p>La mise à disposition de Tipourboire.</p>
                <p>
                  Le choix de la politique de distribution dans son
                  établissement : collective ou individuelle.
                </p>
                <p>
                  Le menu du jour digital et/ou carte de prestations de
                  l'établissement en ne prenant qu’une simple photo.
                </p>
                <p>
                  La mise à disposition des 2 QR Code (QR Code carte pour que
                  les clients voient votre menu et/ou prestations de
                  l'établissement + QR Code addition pour que les clients
                  puissent donner un pourboire).
                </p>

                <br />

                <h3>
                  <strong></strong>Qu’est-ce qui est inclus dans les abonnement
                  propriétaire d'établissement ?
                </h3>

                <p>Pour les propriétaires d'un établissement :</p>
                <p>
                  l’accès à la Data des clients collectée par Tipourboire au
                  sein du restaurant (nom-prénom-mail-téléphone) pour des
                  actions commerciales.
                </p>
                <p>
                  - Choix de la politique de distribution du pourboire
                  (individuelle ou collective).
                </p>
                <p>
                  - le choix de son référant pour distribuer les pourboires
                  collectifs.
                </p>
                <p>
                  - la mise à disposition de 2 QR Code (carte Menu et/ou
                  Prestations + addition).
                </p>
                <p>- les affiliations de ses bénéficiares.</p>
                <br />
                <h3>
                  <strong></strong>Combien coûte un virement de mes pourboires ?
                </h3>
                <p>
                  Avec notre partenaire MangoPay, vous pouvez effectuer le
                  virement de vos pourboires sur votre compte bancaire à
                  n'importe quel moment et ce gratuitement autant de fois que
                  vous le souhaitez dans le mois.
                </p>
              </Col>
            </Row>
          )}

          <Col md={2} s={4} xs={4} className="blocClientFaq">
            <Col>
              <img className="img1 partners" src="/images/group-2.png" />
            </Col>
            <Col>
              <Button className="butPartners" onClick={this.OnClickPartenaire}>
                Partenaire
              </Button>
            </Col>
          </Col>

          {this.state.displayTextPartenaire && (
            <Row className="rowFiscalite">
              <Col>
                <h1 className="sousTitreFaq">
                  <strong>Partenaire</strong>
                </h1>
              </Col>
              <Col md={12}>
                <h3>
                  <strong></strong>Qui sont nos partenaires ?
                </h3>
                <p>Mangopay pour la sécurisation des transactions.</p>

                <p>
                  Coeur de Forêt et 1%ForThePlanet pour notre engagement
                  environnemental.
                </p>
                <p>Bonnie & Cloud pour notre studio de création numérique.</p>
                <br />
              </Col>

              <Col md={12}>
                <h3>
                  <strong></strong>Qui sont les fondateurs de Tipourboire ?
                </h3>
                <p>CEO : Brice Berard-Astic.</p>
                <p>CTO : Philippe Gaspari.</p>
                <br />
              </Col>
            </Row>
          )}
        </Row>
      </Container>
    );
  }
}

export default FAQ;
