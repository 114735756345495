import React, { Component } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import "./waitingPage.css";
import MetaTags from "react-meta-tags";

class waitingPage extends Component {
  render() {
    return (
      <Container fluid>
        <MetaTags>
          <title>Applications bientôt disponible</title>
          <meta
            name="description"
            content="Un peu de patience, votre application sera bientôt disponible !"
          />
          <meta property="og:title" content="Tipourboire" />
        </MetaTags>
        <Row>
          <Col>
            <h1 className="titreWaiting">Tipourboire Arrive bientôt !</h1>
          </Col>
        </Row>
        <Row>
          <Col className="logoWaiting">
            <a href='/'>
            <img src="/images/logoJaune.png" />
            </a>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default waitingPage;
